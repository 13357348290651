.page-container {
    padding: 0px 32px;
}

.actions {
    display: flex;
    justify-content: space-between;
    padding: 32px 0px;
    align-items: center;
}

.action-button: {
   margin: 0px 16px; 
}

.icon {
  font-size: 24px;
  padding: 25px;
  cursor: pointer;
}

.select{
  width: 200px
}

