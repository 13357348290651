.rooftop {
    padding-left: 32px
}

.tab {
  color: #2a42c9;
  border: none;
  font-size: 14px !important;
  font-weight: 550 !important;
  letter-spacing: 0.05em;
}
.tab.current {
  font-weight: bold;
  border-bottom: 4px solid #2a42c9;
}
.links{
  text-decoration: none;
}
