html{
  font-size: 12px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #F0F0F0 !important;
}

code {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.applyAllRootContainer {
  display: flex;
  flex-direction: column;
  height: inherit;
}