.cube {
  margin: 10px;
  padding: 30px;
}
.menu {
  height: 70px;
  display: flex;
  background: white;
  align-items: center;
}
.navStyle {
  color: white;
}
.formcontrol{
    width: 10%;
    font-size: 16px;
}
