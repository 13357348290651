
.TabsContainer {
    width: 100%;
    margin: 0;
    
}

.TabsHeader {
    width: 100%;
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
}
.TabsHeader:hover {
    overflow-x: auto;
}

.Tab {
    display: inline-block;
    padding: 11.5px;
    margin: 0 1px;
    background-color: #F7F7F7;
    cursor: pointer;
    white-space: nowrap;

}

.SelectedTab {
    background-color: #FFFFFF;
}

.SelectedTabContainer {
    background-color: #FFFFFF;
    min-height: 300px;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    margin-top: -2px;
}

h2 {
    margin: 40px 0 0 40px;
}

textarea:focus, input:focus{
    outline: none;
}

.TimeLineContainer {
    margin: 20px 0 20px 40px;
    width: calc(100% - 40px);
    min-height: 70px;
    display: inline-block;
}

.TimeLineItemContainer:hover {
    background-color: #F0F0F0;
}

.TimeLineItemContainer {
    position: relative;
    float: left;
    cursor: pointer;
    height: 95px;
}

.AddTimeLineItem {
    float: left;
    padding: 35px 10px 0 10px;
    height: 60px;
    width: 25px;
    overflow-wrap: anywhere;
    background-color: white;
}

.AddTimeLineItem:hover{
    background-color: #F0F0F0;
    z-index: 20;
    cursor: pointer;
}

.AddTimeLineItem .Icon {
    display: none;
}

.AddTimeLineItem:hover .Icon {
    display: block;
}



.TableDatepicker {
    border: none;
    width: 100px;
    height: 20px;
    color: #656A79;
    background-color: transparent;
    padding: 10px;
    text-align: center;
}
.TimeLineDot {
    width: 12px;
    height: 12px;
    margin: 0 54px 20px 54px;
    border-radius: 8px;
    border: 1px solid #808080;
    background-color: white;
}

.TimeLineDotSelected {
    width: 12px;
    height: 12px;
    margin: 0 54px 20px 54px;
    border-radius: 8px;
    border: 1px solid #808080;
    background-color: #808080;
}

.TimeLineLine {
    height: 1px;
    width: 153px;
    background-color: #808080;
    position: absolute;
    top: 47px;
    left: 68px;
    z-index: 50;

    
}

.SecondaryWarningButton {
    border: #EB1616 solid 1px;
    display: inline-block;
    color: #EB1616;
    border-radius: 5px;
    padding: 0px 15px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin: 20px 10px;
    cursor: pointer;
}
.SecondaryWarningButton:hover {
    background-color: #EB1616;
    color: #FFFFFF;
}

.PrimaryButton {
    display: inline-block;
    background-color: #0033AB;
    border-radius: 5px;
    padding: 0px 15px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin: 20px 10px;
    cursor: pointer;
    color: #FFFFFF;
}
.PrimaryButton:hover {
    box-shadow: 0px 0px 10px 5px #cfcfcf;
}