.PriceTableContainer {
    overflow-x: auto;
    margin: 40px 0 40px 122px;
}

.PricesTable {
    border-collapse: collapse;
    margin: 0;
    display: inline-block;
    table-layout: fixed;
}

.TableHeader {
    background-color: #F0F0F0;
    width: 70px;
    padding: 8px;
    height: 17px;
    border: #FFFFFF solid 1px;
}
.TableRowHeaderContainer{
    border-collapse: collapse;
    margin: 0;
    display: inline-block;
}

.TableRowHeaderEmpty {
    height: 33px;
}

.TableRowHeader {
    position: absolute;
    left: 0px;
    top: auto;
    width: 110px;
    background-color: #F0F0F0;
    padding: 8px;
    border: #FFFFFF solid 0.5px;
}

.TableRowHeader td {
    min-width: 50px;
    display: block;
}

.OptNumberCellSpan {
    margin: 0 5px;
    max-width: 110px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
}

.OptNameCellSpan {
    margin-left: 10px;
    display: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.PriceCell {
    background-color: #FFFFFF;
    min-width: 65px;
    padding: 8px;
    border: #F0F0F0 solid 1px;
    text-align: center;
    height: 15px;
}

.EmptyPriceCell {
    background-color: #f15c5c;
}

.PriceCell:hover {
    background-color: #F7F7F7;
}

.PriceCellInput {
    width: 50px;
    border: none;
    text-align: right;
}
.NotCompatiblePriceCell {
    background-color: #F0F0F0;
    min-width: 40px;
    padding: 8px;
    border: #F0F0F0 solid 1px;
    text-align: center;
}

@media (min-width: 768px) {

    .OptNameCellSpan {
        display: inline-block;
        max-width: 160px;

    }
    .OptNumberCellSpan {
        margin: 0 10px;
    }

    .TableRowHeader {
        width: 300px;
        left: 40px;
    }

    .PriceTableContainer {
      margin: 0 0 40px 352px;
    }

}