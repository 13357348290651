.App-header {
    display: flex;
    min-height: 20%; width: 100%;
  background: radial-gradient(
    52.63% 300.89% at 49.31% 251.79%,
    #1891f6 0%,
    #15205e 100%
  );
  justify-content: space-between;

}

.Header-Logo {
  margin: 10px;
  height: 30px;
  align-content: flex-start;
}
.Header-AccountBtn {
  width: 34px;
  height: 34px;
  margin: 8px;
  padding: 0;
  background-color: #000f7b;
  border-radius: 17px;
  cursor: pointer;
}

.Header-AccountBtnOpen {
  width: 34px;
  border: 2px solid #fff;
  height: 34px;
  margin: 7px;
  border-radius: 18px;
  padding: 0;
  background-color: #565dde;
}

.Header-userIcon {
  height: 14px;
  margin: 10px;
}

.AccountCard {
  background-color: #ffffff;
  position: absolute;
  right: 0;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.AccountCard-Header {
  color: #000000;
  margin: 0;
  padding: 7px;
  font-size: 1rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.3rem;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
}

.AccountCard-Header span {
  line-height: 24px;
  font-size: 14px;
  padding-left: 10px;
}

.AccountCard-UserName {
  color: #000000;
  font-size: 2rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 30px;
  margin: 1.3rem;
  float: left;
  width: 100%;
}

.CloseIcon {
  width: 10px;
  cursor: pointer;
}

.HeaderButton {
  float: left;
}
