.SidePanelContainer {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 101;
    height: 100%;
    overflow: hidden;
    background-color: #f7f7f7;
    box-shadow: 0px 16px 24px 2px rgba(0,0,0,0.3);
    transition-duration: 0.3s;
}
.SidePanelHider {
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.SPOpen {
    transition-duration: 0.3s;
    margin-left: 0%;
    width: 100%;
}
.SPClose {
    margin-left: -100%;
}

.SidePanelHeader {
    background-color: #ffffff;
    height: 24px;
    width: calc(100% - 24px);
    padding: 12px;
}
.SidePanelHeader span {
    font-size: 16px;
    line-height: 24px;
}
.SidePanelCloseIcon {
    width: 10px;
    display: block;
    float: right;
    margin: 7px;
    cursor: pointer;
}

.SidePanelContentContainer{
    margin: 30px;
}

.SidePanelLabel {
    margin: 20px 0 10px 0;
}

.MainPanelButtonContainer {
    background-color: #0033ab;
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.MainPanelButton {
    color: #000000;
    width: calc(100% - 30px);
    height: 34px;
    line-height: 34px;
    margin: 19px 15px 17px 15px;
    font-size: 12px;
    text-align: center;
    border-color: #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    cursor: pointer;
}

@media (min-width: 768px) {
    .SPOpen {
        margin-left: 0%;
        width: 320px;
    }
}